<template>
    <div class="container">
        <div class="is-flex is-align-items-center is-justify-content-center mb-3">
            <figure class="image is-128x128">
                <img src="@/assets/logo.svg">
            </figure>
        </div>
        <div class="columns is-centered">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="card-header">
                        <p class="card-header-title">
                            <span class="icon"><i class="mdi mdi-lock default"></i></span>
                            <span>{{ $t('Sign up') }}</span>
                        </p>
                    </header>
                    <div class="card-content">
                        <form @submit.prevent="signUp">
                            <b-field :label="$t('First name')" label-position="on-border" :type="{ 'is-danger': errors.firstName }" :message="errors.firstName">
                                <b-input type="text" v-model="firstName"></b-input>
                            </b-field>
                            <b-field :label="$t('Last name')" label-position="on-border" :type="{ 'is-danger': errors.lastName }" :message="errors.lastName">
                                <b-input type="text" v-model="lastName"></b-input>
                            </b-field>
                            <b-field :label="$t('Username')" label-position="on-border" :type="{ 'is-danger': errors.account }" :message="errors.account">
                                <b-input type="text" v-model="account"></b-input>
                            </b-field>
                            <b-field :label="$t('E-mail')" label-position="on-border" :type="{ 'is-danger': errors.email }" :message="errors.email">
                                <b-input type="email" v-model="email"></b-input>
                            </b-field>
                            <b-field :label="$t('Password')" label-position="on-border" :type="{ 'is-danger': errors.password }" :message="errors.password">
                                <b-input type="password" v-model="password"></b-input>
                            </b-field>
                            <b-field :label="$t('Repeat password')" label-position="on-border" :type="{ 'is-danger': errors.repeatPassword }" :message="errors.repeatPassword">
                                <b-input type="password" v-model="repeatPassword"></b-input>
                            </b-field>
                            <div class="field">
                                <button type="submit" class="button is-link" :class="{ 'is-loading': isSubmitting }"> {{ $t('Sign up') }}</button>
                            </div>
                            <hr>
                            <div class="has-text-centered is-size-7">
                                <router-link to="/recover-password">{{ $t('Recover password') }}</router-link> &middot;
                                <router-link to="/sign-in">{{ $t('Sign in') }}</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {encryptData} from "@/utils";

export default {
    name: "SignUp",
    mounted() {
        document.title = this.$t('Sign up') + ' - GIS solution'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Sign up') + ' - GIS solution'
        }
    },
    data() {
        return {
            errors: {},
            account: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repeatPassword: '',
            isSubmitting: false
        }
    },
    methods: {
        isValid() {
            this.errors = {}
            if (!this.account) {
                this.errors.account = this.$t('This field is mandatory!')
            }
            if (!this.firstName) {
                this.errors.firstName = this.$t('This field is mandatory!')
            }
            if (!this.lastName) {
                this.errors.lastName = this.$t('This field is mandatory!')
            }
            if (!this.email) {
                this.errors.email = this.$t('This field is mandatory!')
            }
            if (!this.password) {
                this.errors.password = this.$t('This field is mandatory!')
            } else if (this.password !== this.repeatPassword) {
                this.errors.password = this.$t('Passwords miss match!')
                this.errors.repeatPassword = this.$t('Passwords miss match!')
            }
            return !Object.keys(this.errors).length
        },
        signUp() {
            if (!this.isValid()) {
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t('Check the errors in page!'),
                    type: 'is-danger'
                })
                return false
            }
            this.isSubmitting = true
            this.$store.dispatch('signUp', {
                account: this.account,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: encryptData(this.password),
                confirmationPassword: encryptData(this.repeatPassword)
            }).then(response => {
                this.isSubmitting = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t('Your account has been created!'),
                    type: 'is-success'
                })
                this.$router.push('/')
            }).catch(error => {
                this.isSubmitting = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t(error.response ? error.response.data.message : error),
                    type: 'is-danger'
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
